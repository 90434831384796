import React, { useEffect, Suspense } from 'react'

import 'firebase/auth'
import 'firebase/storage'
import { FirebaseAppProvider, useAuth } from 'reactfire'

import { FullScreen, Spinner } from '@pearly/lib'

const firebaseConfig = {
  apiKey: process.env.GATSBY_FIREBASE_KEY,
  authDomain: 'trublu-membership.firebaseapp.com',
  storageBucket: 'trublu-app'
}

const FirebaseProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <Suspense
        fallback={
          <FullScreen display="flex" justifyContent="center" alignItems="center">
            <Spinner />
          </FullScreen>
        }
      >
        <SetTenant>{children}</SetTenant>
      </Suspense>
    </FirebaseAppProvider>
  )
}

const SetTenant = ({ children }: { children: React.ReactNode }) => {
  const firebaseAuth = useAuth()

  useEffect(() => {
    firebaseAuth.tenantId = process.env.GATSBY_IDP_TENANTID ?? null
  }, [firebaseAuth])

  return <>{children}</>
}

export default FirebaseProvider
