import React from 'react'
import { auth } from 'firebase'

import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'

import { ApolloProvider as SourceApolloProvider } from '@apollo/react-hooks'

export type Props = {
  children: React.ReactNode
}

const httpLink = createHttpLink({
  uri: process.env.GATSBY_API_URL
})

const secureHttpLink = createHttpLink({
  uri: process.env.GATSBY_SECURE_API_URL
})

const createApolloClient = () => {
  const authLink = setContext(async (_, { headers }) => {
    try {
      // Throws error if auth() is not initialized (Firebase Provider)
      const token = await auth().currentUser?.getIdToken()
      return token
        ? {
            headers: {
              ...headers,
              authorization: `Bearer ${token}`
            }
          }
        : headers
    } catch {
      return headers
    }
  })

  return new ApolloClient({
    link: authLink.split(operation => !!operation.getContext().secure, secureHttpLink, httpLink),
    cache: new InMemoryCache(),
    name: 'Member App'
  })
}

const ApolloProvider = ({ children }: Props) => (
  <SourceApolloProvider client={createApolloClient()}>{children}</SourceApolloProvider>
)

export default ApolloProvider
