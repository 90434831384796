import { ContractSheet, createModalProvider, EnrollMemberSheet, SendInviteDialog, ConfirmDialog } from '@pearly/lib'

// import ContractSheet from 'components/_sheets/contract-sheet'

const sheetComponents = {
  contract: ContractSheet,
  enrollMember: EnrollMemberSheet,
  sendInvite: SendInviteDialog,
  confirm: ConfirmDialog
}

export const { ModalProvider, useModal } = createModalProvider(sheetComponents)
