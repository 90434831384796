import * as Types from 'types'
import gql from 'graphql-tag'
import { SavedTokenInput } from '@pearly/lib'

export type enrollMember = 'String'

export enum PlanTerm {
  MONTHLY = 'MONTHLY',
  ANNUAL = 'ANNUAL'
}

export interface EnrollNewMemberVariables {
  accountId: string // ONLY ON MEMBER
  idToken: string
  secure: Types.SecureContact | string
  planId: string
  planTerm: PlanTerm
  isManual: boolean
  // coupon?: string
  creditCardToken?: SavedTokenInput
}

export const ENROLL_NEW_MEMBER = gql`
  mutation enrollMember(
    $accountId: ID!
    $idToken: String!
    $secure: String!
    $planId: ID!
    $planTerm: PlanTerm!
    $isManual: Boolean!
    $creditCardToken: SavedTokenInput
  ) {
    enrollMember(
      accountId: $accountId
      idToken: $idToken
      secure: $secure
      planId: $planId
      planTerm: $planTerm
      creditCardToken: $creditCardToken
      isManual: $isManual
    ) {
      id
    }
  }
`

export type UpdateContactProfile = {
  id: string
  secure: Types.SecureContact | string
}

export type UpdateContactProfileVariables = {
  id: string
  secure: Types.SecureContact | string
}

export const UPDATE_CONTACT_PROFILE = gql`
  mutation UpdateContactProfile($id: ID!, $secure: String!) {
    updateContactProfile(id: $id, secure: $secure) {
      secure
    }
  }
`
