import React from 'react'

import { Button, ButtonProps, colors } from '@pearly/lib'

export interface Props extends ButtonProps {
  color?: string
  children: React.ReactNode
}

const HeaderButton = ({ color = colors.neutral.base, children, ...props }: Props) => (
  <Button size={500} height={48} appearance="minimal" color={`${color} !important`} {...props}>
    {children}
  </Button>
)

export default HeaderButton
